.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
}

.card {
  display: grid;
  grid-template-rows: max-content 50px 1fr;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  overflow: hidden;
  border:rgba(0, 0, 0, 0.2) solid 1px;
}

.card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
