.w-100 { width: 100%; }
.w-5 { width: 5rem; }
.w-4 { width: 2rem; }
.w-3 { width: 1rem; }
.w-2 { width: 0.5rem; }
.w-1 { width: 0.25rem; }
.w-0 { width: 0.0rem; }
.h-5 { height: 5rem; }
.h-4 { height: 2rem; }
.h-3 { height: 1rem; }
.h-2 { height: 0.5rem; }
.h-1 { height: 0.25rem; }
.h-0 { height: 0.0rem; }
