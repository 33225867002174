.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-5 {
  flex: 5rem;
}

.flex-6 {
  flex: 10rem;
}

.flex-gap-3 {
  gap: 1rem;
}

.w-100 {
  width: 100%;
}

.w-5 {
  width: 5rem;
}

.w-4 {
  width: 2rem;
}

.w-3 {
  width: 1rem;
}

.w-2 {
  width: .5rem;
}

.w-1 {
  width: .25rem;
}

.w-0 {
  width: 0;
}

.h-5 {
  height: 5rem;
}

.h-4 {
  height: 2rem;
}

.h-3 {
  height: 1rem;
}

.h-2 {
  height: .5rem;
}

.h-1 {
  height: .25rem;
}

.h-0 {
  height: 0;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 768px) {
  .container {
    width: 750px;
  }
}

@media (width >= 992px) {
  .container {
    width: 970px;
  }
}

@media (width >= 1200px) {
  .container {
    width: 1170px;
  }
}

.p-5 {
  padding: 5rem;
}

.p-4 {
  padding: 2rem;
}

.p-3 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-1 {
  padding: .25rem;
}

.p-0 {
  padding: 0;
}

.px-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-5 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-0 {
  padding-top: 0;
}

.pr-6 {
  padding-right: 10rem;
}

.pr-5 {
  padding-right: 5rem;
}

.pr-4 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-0 {
  padding-right: 0;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-5 {
  padding-left: 5rem;
}

.pl-4 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-0 {
  padding-left: 0;
}

.m-5 {
  margin: 5rem;
}

.m-4 {
  margin: 2rem;
}

.m-3 {
  margin: 1rem;
}

.m-2 {
  margin: .5rem;
}

.m-1 {
  margin: .25rem;
}

.m-0 {
  margin: 0;
}

.mx-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-0 {
  margin-top: 0;
}

.mr-5 {
  margin-right: 5rem;
}

.mr-4 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-0 {
  margin-right: 0;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-5 {
  margin-left: 5rem;
}

.ml-4 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-0 {
  margin-left: 0;
}

.text-right {
  text-align: right;
}

.cards {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  display: grid;
}

.card {
  border: 1px solid #0003;
  border-radius: 5px;
  grid-template-rows: max-content 50px 1fr;
  transition: all .3s;
  display: grid;
  overflow: hidden;
  box-shadow: 0 4px 8px #0003;
}

.card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.link {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

body {
  font-size: 16px;
}

/*# sourceMappingURL=index.296f743b.css.map */
