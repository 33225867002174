.container {
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.p-5 { padding: 5rem; }
.p-4 { padding: 2rem; }
.p-3 { padding: 1rem; }
.p-2 { padding: 0.5rem; }
.p-1 { padding: 0.25rem; }
.p-0 { padding: 0.0rem; }

.px-5 { padding-left: 5rem; padding-right: 5rem; }
.px-4 { padding-left: 2rem; padding-right: 2rem; }
.px-3 { padding-left: 1rem; padding-right: 1rem; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.px-1 { padding-left: 0.25rem; padding-right: 0.25rem; }
.px-0 { padding-left: 0rem; padding-right: 0.0rem; }

.py-5 { padding-top: 5rem; padding-bottom: 5rem; }
.py-4 { padding-top: 2rem; padding-bottom: 2rem; }
.py-3 { padding-top: 1rem; padding-bottom: 1rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.py-0 { padding-top: 0.0rem; padding-bottom: 0.0rem; }

.pt-5 { padding-top: 5rem; }
.pt-4 { padding-top: 2rem; }
.pt-3 { padding-top: 1rem; }
.pt-2 { padding-top: 0.5rem; }
.pt-1 { padding-top: 0.25rem; }
.pt-0 { padding-top: 0.0rem; }

.pr-6 { padding-right: 10rem; }
.pr-5 { padding-right: 5rem; }
.pr-4 { padding-right: 2rem; }
.pr-3 { padding-right: 1rem; }
.pr-2 { padding-right: 0.5rem; }
.pr-1 { padding-right: 0.25rem; }
.pr-0 { padding-right: 0.0rem; }

.pb-5 { padding-bottom: 5rem; }
.pb-4 { padding-bottom: 2rem; }
.pb-3 { padding-bottom: 1rem; }
.pb-2 { padding-bottom: 0.5rem; }
.pb-1 { padding-bottom: 0.25rem; }
.pb-0 { padding-bottom: 0.0rem; }

.pl-5 { padding-left: 5rem; }
.pl-4 { padding-left: 2rem; }
.pl-3 { padding-left: 1rem; }
.pl-2 { padding-left: 0.5rem; }
.pl-1 { padding-left: 0.25rem; }
.pl-0 { padding-left: 0.0rem; }

.m-5 { margin: 5rem; }
.m-4 { margin: 2rem; }
.m-3 { margin: 1rem; }
.m-2 { margin: 0.5rem; }
.m-1 { margin: 0.25rem; }
.m-0{ margin: 0.0rem; }

.mx-5 { margin-left: 5rem; margin-right: 5rem; }
.mx-4 { margin-left: 2rem; margin-right: 2rem; }
.mx-3 { margin-left: 1rem; margin-right: 1rem; }
.mx-2 { margin-left: 0.5rem; margin-right: 0.5rem; }
.mx-1 { margin-left: 0.25rem; margin-right: 0.25rem; }
.mx-0 { margin-left: 0.0rem; margin-right: 0.0rem; }

.my-5 { margin-top: 5rem; margin-bottom: 5rem; }
.my-4 { margin-top: 2rem; margin-bottom: 2rem; }
.my-3 { margin-top: 1rem; margin-bottom: 1rem; }
.my-2 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.my-1 { margin-top: 0.25rem; margin-bottom: 0.25rem; }
.my-0 { margin-top: 0.0rem; margin-bottom: 0.0rem; }

.mt-5 { margin-top: 5rem; }
.mt-4 { margin-top: 2rem; }
.mt-3 { margin-top: 1rem; }
.mt-2 { margin-top: 0.5rem; }
.mt-1 { margin-top: 0.25rem; }
.mt-0 { margin-top: 0.0rem; }

.mr-5 { margin-right: 5rem; }
.mr-4 { margin-right: 2rem; }
.mr-3 { margin-right: 1rem; }
.mr-2 { margin-right: 0.5rem; }
.mr-1 { margin-right: 0.25rem; }
.mr-0 { margin-right: 0.0rem; }

.mb-5 { margin-bottom: 5rem; }
.mb-4 { margin-bottom: 2rem; }
.mb-3 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 0.5rem; }
.mb-1 { margin-bottom: 0.25rem; }
.mb-0 { margin-bottom: 0.0rem; }

.ml-5 { margin-left: 5rem; }
.ml-4 { margin-left: 2rem; }
.ml-3 { margin-left: 1rem; }
.ml-2 { margin-left: 0.5rem; }
.ml-1 { margin-left: 0.25rem; }
.ml-0 { margin-left: 0.0rem; }
